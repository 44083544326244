/** UTIL functions **/

//GLOBAl - an instance of the Utils class
var utils = new Utils();

function Utils() {
}

// Serves the specific purpose of inserting table rows in the form of html before a DOM tr element
// e.g.	insertHTMLBefore(document.getElementById('tr_eg'), "<tr><td>r2d1</td> <td>r2d2</td></tr>   <tr><td>r4d1</td> <td>r4d2</td></tr>")
//		will insert the 2 tr's in html text before the tr_eg node
// @param insBefore		Dom node (<tr>)
// @param html			String ('<tr>' (may be multiple))
Utils.prototype.insertHTMLbefore = function (insBefore, html) {
    //create a dummy tbody node, then insert JSON html (this will crate dom nodes which we can then extract and insert before ins)
    var temp = document.createElement('tbody');
    temp.innerHTML = html;
    var elements = temp.childNodes;		//exract elements as DOM nodes
    for (var i = elements.length - 1; i >= 0; i--)
        insBefore.parentNode.insertBefore(elements[i], insBefore);		// insert DOM nodes before insBefore
}


/**
 * If the width<90px or height<20px of the element being set to loading then the loading overlay will extend outside the element
 *
 * DEPENDANCY's:
 *   this.getTopPos(elem)
 *   this.getLeftPos(elem)
 *   this.onTransistionEnd(elem)
 *
 * @param id element's id that needs to be set as loading
 * @param set whether or not the element should true:have the loading status set, or false:have loading status removed
 * @param {bool}{optional}{default:true} true: will disable/enable inputs - false: will not
 */
Utils.prototype.setLoading = function (elem, set, disableInputs) {
    if (typeof disableInputs === 'undefined') disableInputs = true;
    if (disableInputs) {
        var inputs = elem.getElementsByTagName('input');
        for (var i = 0; i < inputs.length; ++i)
            inputs[i].disabled = set;
    }

    var overlayid;

    if (set) {
        //Set loading overlay
        var div_overlay = document.createElement('div');
        var div_inner = document.createElement('div');
        var img_throbber = document.createElement('img');

        overlayid = 'overlay_' + Math.random().toString(36).substr(2, 6);		//Create a random id that is probably unique
        div_overlay.id = overlayid;
        //div_overlay.className = 'fade';

        div_overlay.style.backgroundColor = 'white';
        div_overlay.style.WebkitTransition = 'opacity 1s';
        div_overlay.style.MozTransition = 'opacity 1s';
        div_overlay.style.OTransition = 'opacity 1s';
        div_overlay.style.transition = 'opacity 1s';
        //div_overlay.innerHTML = "loading..."
        div_overlay.appendChild(div_inner);
        div_inner.appendChild(img_throbber);

        // Only add the loading text if width is > 90 (otherwise it overhangs)
        if (elem.offsetWidth > 90) {
            var span_loading = document.createElement('span');
            div_inner.appendChild(span_loading);
            span_loading.innerHTML = "loading...";
            div_inner.style.width = '90px';
        } else {
            div_inner.style.width = '16px';
        }

        //img_throbber.setAttribute('href', '/styles/images/ajax-loader_eip.gif');
        img_throbber.src = '/styles/images/ajax-loader_eip.gif';
        img_throbber.style.marginRight = '5px';

        //center loading in inner div
        div_inner.style.margin = '-10px auto 0 auto';
        div_inner.style.top = '50%';
        div_inner.style.position = 'relative';

        //position overlay
        div_overlay.style.width = elem.offsetWidth + 'px';
        div_overlay.style.height = elem.offsetHeight + 'px';
        div_overlay.style.top = this.getTopPos(elem) + 'px';
        div_overlay.style.left = this.getLeftPos(elem) + 'px';
        div_overlay.style.position = 'absolute';
        div_overlay.style.opacity = '0.0';
        div_overlay.style.zIndex = '1000';
        if (window.getComputedStyle) {
            var computedStyle = window.getComputedStyle(div_overlay);
            if (computedStyle)
                computedStyle.getPropertyValue("opacity");
        }

        document.body.appendChild(div_overlay);

        //Save overlayid so that we can remove it later on
        elem.overlayid = overlayid;

        div_overlay.style.opacity = '1.0';
    } else {
        //Remove loading overlay
        var overlay = document.getElementById(elem.overlayid);

        // Calls getComputedStyle() on element to make sure that opacity is applied before changing it
        this.getElemStyle(overlay, 'opacity');

        overlay.style.opacity = '0.0';
        //window.setTimeout(function() { overlay.parentNode.removeChild(overlay); }, 1000);
        this.onTransistionEnd(overlay, function () {
            overlay.parentNode.removeChild(overlay)
        }.bind(overlay));
        //this.onTransistionEnd(overlay, function() { this.parentNode.removeChild(this) });
        //overlay.addEventListener("webkitTransitionEnd", function() { this.parentNode.removeChild(this) }, false);

        //overlay.parentNode.removeChild(overlay);
    }
}

//Set error for a <input> elem passed in (will give a red outline, and on keydown/click will change it back to what is was)
Utils.prototype.setError = function (elem) {

    if (elem.savedValues) return;     //Error already set

    //Save values that need to be restored
    elem.savedValues = {};
    //savedValues.backgroundColor = elem.style.backgroundColor;  //Most likely = ''
    elem.savedValues.outlineColor = elem.style.outlineColor;  //Most likely = ''
    elem.savedValues.outlineStyle = elem.style.outlineStyle;  //Most likely = ''

    //elem.style.backgroundColor = 'red';
    elem.style.outlineColor = 'red';
    elem.style.outlineStyle = 'solid';

    var removeError = function () {
        elem.style.outlineColor = elem.savedValues.outlineColor;  //Most likely = ''
        elem.style.outlineStyle = elem.savedValues.outlineStyle;  //Most likely = ''
        //elem.style.backgroundColor = saveBackground;    //Remove red background

        //Remove this event(itself)
        if (elem.addEventListener) {
            elem.removeEventListener("keydown", removeError, false);
            elem.removeEventListener("input", removeError, false);
            elem.removeEventListener("click", removeError, false);
        } else {
            elem.detachEvent("onkeydown", removeError);    //IE pre V9
            elem.detachEvent("onclick", removeError);    //IE pre V9
        }

        delete elem.savedValues;
    };

    if (elem.addEventListener) {
        elem.addEventListener("keydown", removeError, false);
        elem.addEventListener("input", removeError, false);
        elem.addEventListener("click", removeError, false);
    } else {
        elem.attachEvent("onkeydown", removeError);    //IE pre V9
        elem.attachEvent("onclick", removeError);    //IE pre V9
    }
}

//Call function when DOM is ready (safe to use if DOM is already ready)
Utils.prototype.onDomReady = function (func) {
    if (document.readyState == "complete") {
        func();
    } else {
        //Add OnDOMready event
        if (document.addEventListener)
            document.addEventListener("readystatechange", function () {
                if (document.readyState == "complete") func();
            }, false);
        else
            document.attachEvent("onreadystatechange", function () {
                if (document.readyState == "complete") func();
            });	//IE pre V9
    }
}

// Simulate an event on an element
Utils.prototype.simEvent = function (element, eventType) {
    if ("createEvent" in document) {
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent(eventType, false, true);
        element.dispatchEvent(evt);
    } else {
        element.fireEvent("on" + eventType);
    }
}

// Simulate an event on an element
Utils.prototype.simEvent = function (element, eventType) {
    if ("createEvent" in document) {
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent(eventType, false, true);
        element.dispatchEvent(evt);
    } else {
        element.fireEvent("on" + eventType);
    }
}

//Get the left position relative to window
Utils.prototype.getLeftPos = function (el) {
    for (var leftPos = 0; el != null; leftPos += el.offsetLeft, el = el.offsetParent);
    return leftPos;
}

//Get the top position relative to window
Utils.prototype.getTopPos = function (el) {
    for (var topPos = 0; el != null; topPos += el.offsetTop, el = el.offsetParent);
    return topPos;
}

/**
 * Get the left and top position of an element relative to window
 * @param el {DOMElement} - the element that we want to find the {left, top} of
 * @param middle {Boolean} - Whether to get the x,y of the middle of the element rather than the top left
 * @returns {Object} - { 'x':{int}, 'y':{int} }        e.g. { 'x':1180, 'y':500 } (Note: x=left, y=top)
 */
Utils.prototype.getLTPos = function (el, middle) {
    var pos;
    if (middle) pos = {'x': el.offsetWidth / 2, 'y': el.offsetHeight / 2};
    else pos = {'x': 0, 'y': 0};
    for (; el != null; pos.x += el.offsetLeft, pos.y += el.offsetTop, el = el.offsetParent);
    return pos;
}

//Checks if 'decendant' is a decendant of an ancestor ('ancestor'). Return true if true and false otherwise.
Utils.prototype.isDecendant = function (decendant, ancestor) {
    return ((decendant.parentNode == ancestor) ||
    (decendant.parentNode != document) &&
    isDecendant(decendant.parentNode, ancestor));
}

//Remove a class from an element
Utils.prototype.removeClass = function (ele, cls) {
    ele.className = ele.className.replace(new RegExp('(^|\\s+)' + cls + '($|\\s+)', 'g'), ' ').trim();
    return ele;
}

//Add a class to an element
Utils.prototype.addClass = function (ele, cls) {
    var eCls = ele.className.trim().split(' ');

    if (eCls.indexOf(cls) === -1)
        eCls.push(cls);

    ele.className = eCls.join(' ').trim();
    return ele;
}

//Send an ajax request (either GET or POST)
// @param {string} type - "POST"/"GET"
// @param {string} url - e.g. "example.com"/"../file.php"
// @param {Object/string} params - Object: {'name1':'value1', 'name2':'value2'} / String: "name1=value1&name2=value2"
// @param {function} onResponse - function(xmlhttp) {alert(xmlhttp.responseText);}
Utils.prototype.ajax = function (type, url, params, onResponse) {
    var xmlhttp;
    if (window.XMLHttpRequest)
        xmlhttp = new XMLHttpRequest(); // code for IE7+, Firefox, Chrome, Opera, Safari
    else
        xmlhttp = new ActiveXObject("Microsoft.XMLHTTP"); // code for IE6, IE5

    var strParams;

    //If params is an object then convert to a string
    if (typeof params === 'object') {
        //Turn params into a string that we can send
        strParams = "";
        for (var param in params)
            strParams += param + "=" + encodeURIComponent(params[param]) + "&";
        strParams = strParams.substr(0, strParams.length - 1);
    } else {
        strParams = params;
    }

    // If url is blank then use the current page's name
    if (typeof url === 'undefined' || !url) {
        url = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
        if (window.location.search.substring(1).length > 0)
            url += '?' + window.location.search.substring(1);	// Add querystring
    }
    if (url.substr(0, 1) === "?") url = location.pathname.substring(location.pathname.lastIndexOf("/") + 1) + url;

    if (type.toUpperCase() === "POST") {
        xmlhttp.open("POST", url, true);

        //Send the proper header information along with the request
        xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    } else {
        xmlhttp.open("GET", url + (url.indexOf("?") === -1 ? "?" : "&") + strParams, true);
    }

    xmlhttp.onreadystatechange = function () {	//Called when state changes.
        if (xmlhttp.readyState == 4) {
            onResponse(xmlhttp);
        }
    };
    xmlhttp.send(type.toUpperCase() === "POST" ? strParams : null);
}

/**
 * Expands on ajax(). This will send a <form> via AJAX
 * Only elements with a 'name' will be added
 *  DEPENDENCY: this.ajax(type, url, params, onResponse)
 *
 * @param {object HTMLFormElement} form - form e.g. Form1 (without quotes)
 * @param {function} onResponse - function(xmlhttp) {alert(xmlhttp.responseText);}
 * @param {string} url {Optional} - e.g. "example.com"/"../file.php". If not specified we will get this from the <form>.action elem.
 * @param {string} type {Optional} - "POST"/"GET". If not specified we will try get this from <form>.method elem, and if we cant do that it will default to 'GET'.
 *
 * @see Utils#ajax(type, url, params, onResponse)
 */
Utils.prototype.formAjax = function (form, onResponse, url, type) {
    //First get a param string from out of the form
    var params = this.formString(form);

    //Get type of submission GET/POST
    if (typeof type === 'undefined')
        type = form.getAttribute('method').toUpperCase() === 'POST' ? 'POST' : 'GET';

    //Get url
    if (typeof url === 'undefined')
        url = form.getAttribute('action');

    this.ajax(type, url, params, onResponse);
}

/**
 * Expands on jsonAjax(). This will send a <form> via AJAX and response to onResponse will be a JSON object
 * Use this when requesting a JSON string using AJAX.
 * Only elements with a 'name' will be added
 *  DEPENDENCY: this.jsonAjax(type, url, params, onResponse)
 *
 * @param {object HTMLFormElement} form - form e.g. Form1 (without quotes)
 * @param {function} onResponse - function(xmlhttp) {alert(xmlhttp.responseText);}
 * @param {string} url {Optional} - e.g. "example.com"/"../file.php". If not specified we will get this from the <form>.action elem.
 * @param {string} type {Optional} - "POST"/"GET". If not specified we will try get this from <form>.method elem, and if we cant do that it will default to 'GET'.
 *
 * @see Utils#ajax(type, url, params, onResponse)
 */
Utils.prototype.formJsonAjax = function (form, onResponse, url, type) {
    //First get a param string from out of the form
    var params = this.formString(form);

    //Get type of submission GET/POST
    if (typeof type === 'undefined')
        type = form.getAttribute('method').toUpperCase() === 'POST' ? 'POST' : 'GET';

    //Get url
    if (typeof url === 'undefined')
        url = form.getAttribute('action');

    this.jsonAjax(type, url, params, onResponse);
}

// Expands on ajax(). This will return the equivalent of a JSON decoded Object, whether it passes or fails
// Use this when requesting a JSON string using AJAX.
//  DEPENDENCY: this.ajax(type, url, params, onResponse)
//
// @param {string} type - "POST"/"GET"
// @param {string} url - e.g. "example.com"/"../file.php"
// @param {Object} params - {'name1':'value1', 'name2':'value2'}
// @param {function} onResponse - function(jresponse) {alert(jresponse.status);}
//
// @return {Object}
//      - onFail: {'status':'failed', 'errmess':xmlhttp.responseText}
//      - onSuccess: JSON.parse(xmlhttp.responseText)
Utils.prototype.jsonAjax = function (type, url, params, onResponse) {
    this.ajax(type, url, params, function (xmlhttp) {
        var response;
        try {
            response = JSON.parse(xmlhttp.responseText);
        } catch (e) {
            response = {'status': 'failed', 'errors': [xmlhttp.responseText]};
            if (e.message) response.errors.unshift(e.message);
        }

        response.xmlhttp = xmlhttp;

        onResponse(response);
    });
}

/**
 * Get a parameter string for ajax out of a form.
 * Note: <input type='file'> does not work
 * @param form {Form (DOMElement)} - e.g. document.forms[0]
 * @returns {string} - of the form 'name1=value1&name2=value2'
 */
Utils.prototype.formString = function (form) {
    var params = "";		//Returned value
    for (var i = 0; i < form.elements.length; i++)		//For each form element
        if (form.elements[i].name.length > 0)	//Only add form elements with a name.length > 0
            if ((form.elements[i].type !== 'checkbox' && form.elements[i].type !== 'radio') || form.elements[i].checked) //Dont add radio/checkboxes that aren't checked
                params += "&" + form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value);
    params = params.substr(1, params.length - 1);
    return params;
}

/**
 * Get a parameter string for ajax out of a form.
 * Note: <input type='file'> does not work
 * @param form {Form (DOMElement)} - e.g. document.forms[0]
 * @param notForm {Boolean}{optional} - if this is true then @param(form) isn't a form in which case the <input>'s will be found like elem.querySelectorAll('input')
 * @returns {string} - of the form 'name1=value1&name2=value2'
 */
Utils.prototype.formToObj = function (form, notForm) {
    var elements;
    var params = {};		//Returned value
    elements = typeof notForm !== 'undefined' && notForm ? form.querySelectorAll('input, textarea, select') : form.elements;
    for (var i = 0; i < elements.length; i++) {		//For each form element
        if (elements[i].name.length > 0) {	//Only add form elements with a name.length > 0
            switch (elements[i].type) {
                case 'checkbox':
                    if (elements[i].checked) params[elements[i].name] = elements[i].value;
                    break;
                case 'radio':
                    if (elements[i].checked) params[elements[i].name] = elements[i].value;
                    break;
                default:
                    params[elements[i].name] = elements[i].value;
            }
        }
    }
    return params;
}

/**
 * This should be passed an array of errors that have occurred with keys of the elements that are in error and will return the same array but will highlight as elements on the page the have an id/name of the key's passed in.
 * @param errors {Object} - an associative array of errors with key's of element id/name's on this page, and values of a description of the error that occurred.
 * @param list {Bool}{optional}{default:false} - returns a string representation of the array as a list e.g. "<li>item1</li><li>item2</li>"
 */
Utils.prototype.errorsToArray = function (errors, list) {
    if (typeof list === 'undefined') list = false;		// default list to false
    if (typeof errors === 'undefined') return false;		// return false if errors isn't defined

    var arr = new Array();
    for (var key in errors) {
        if (isNaN(key)) {		// We don't want elements to be highlighted if key is e.g. 0
            var elem = document.getElementById(key);
            if (elem) {
                utils.setError(elem);
            } else {
                var elems = document.getElementsByName(key);
                for (var i = 0; i < elems.length; i++) {
                    utils.setError(elems[i]);
                }
            }
        }
        arr.push(errors[key]);
    }

    if (list)
        if (arr.length > 0)
            return "<li>" + arr.join("</li>\n<li>") + "</li>";

    return arr;
}

//Check if a string is an int
Utils.prototype.isInt = function (value) {
    return /^\d+$/.test(value);
}

/**
 * Clip a value between a min and max.
 * e.g. - (2, 1, 5) will return 2
 *      - (-1, 1, 5) will return 1 (clipped)
 *      - (21, 1, 5) will return 5 (clipped)
 *      - (0, -1, 50) will return 0
 * If null or undefined passed in, result is undefined.
 * @param {int} value - The value that is to be clipped (may be > max or < min)
 * @param {int} min - The minimum value that is to be returned
 * @param {int} max - The maximum value that is to be returned
 **/
Utils.prototype.clip = function (value, min, max) {
    return value < min ? min : (value > max ? max : value);
}

/**
 * Merge two javascript Objects. Overwrites obj1's values with obj2's and adds obj2's if non existent in obj1
 * @param obj1
 * @param obj2
 * @returns obj3 a new object based on obj1 and obj2
 */
Utils.prototype.merge_objects = function (obj1, obj2) {
    var obj3 = {};
    for (var attrname in obj1) {
        obj3[attrname] = obj1[attrname];
    }
    for (var attrname in obj2) {
        obj3[attrname] = obj2[attrname];
    }
    return obj3;
}

/**
 * Disable a <a href>. Works for nw_btn's.
 * IE<9 limitation: Events added with elem.attachEvent WILL NOT be disabled, but in any other browser events will be disabled.
 * @param {DOMElement} elem - the <a href> to be disabled
 * @param {string} message(optional) - will be added to the disabled <a href>'s title
 */
Utils.prototype.disableHref = function (elem, message) {
    //check if elem has already been disabled
    if (typeof elem.savedValues !== 'undefined'
        && (typeof elem.savedValues.colour !== 'undefined'
        || typeof elem.savedValues.event_preventDefault !== 'undefined'
        || typeof elem.savedValues.classNames !== 'undefined'))
        return;		//If we have access to the saved href color or the prevent default event, then element is already disabled

    //Save the current text colour, title(if needed) and the prevent default event
    elem.savedValues = {};
    elem.savedValues.colour = elem.style.color;     //Most likely ''
    if (typeof message !== 'undefined') elem.savedValues.title = elem.title;     //Most likely ''

    elem.savedValues.event_preventDefault = function (evt) {
        if (evt.target) {
            // IE>8 or nonIE
            if (evt.target === elem) {
                console.log('prevent default');
                evt.stopPropagation();
                evt.preventDefault();
            }
        } else {
            //IE<9
            if (evt.srcElement === elem) {
                evt.cancelBubble = true;	//Pointless because IE's events only bubble(as we are attaching this event handler to the <a> we cant stop events firing that are attached directly to it)		//IE<9
                evt.returnValue = false;		//IE<9
            }
        }
    };

    //Change the colour of the link so it appears inactive
    var obj_classNames = {      //Object where key=classname to be replaced value=classname to replace with
        'nw_btn_g': 'nw_btn_gray nw_btn_noselect',
        'nw_btn_r': 'nw_btn_gray nw_btn_noselect'
    };
    for (var key in obj_classNames) {        // replace each obj_classNames and save state so that it can be restored
        if (elem.className.indexOf(key) !== -1) {
            elem.className = elem.className.replace(key, obj_classNames[key]);
            if (typeof elem.savedValues.classNames === 'undefined') elem.savedValues.classNames = new Array();
            elem.savedValues.classNames.push([key, obj_classNames[key]]);
        }
    }
    if (typeof elem.savedValues.classNames === 'undefined')      // If no obj_classNames where found then
        elem.style.color = '#BBB';      //Change text color to gray

    //Change the title to the message parsed in
    if (typeof elem.savedValues.title !== 'undefined') elem.title = message;

    //Add the prevent default event for onclick
    if (elem.addEventListener)
        window.addEventListener("click", elem.savedValues.event_preventDefault, true);
    else
        document.attachEvent("onclick", elem.savedValues.event_preventDefault);	//IE<9

}

/**
 * Enable a previously disabled <a href>
 * @param {DOMElement} elem - the <a href>
 */
Utils.prototype.enableHref = function (elem) {

    if (typeof elem.savedValues === 'undefined'
        || typeof elem.savedValues.event_preventDefault === 'undefined')
        return;		//If we dont have access to the saved href color or the prevent default event, then element is already enabled

    //Restore values
    if (typeof elem.savedValues.colour !== 'undefined') elem.style.color = elem.savedValues.colour;	//restore colour

    if (typeof elem.savedValues.title !== 'undefined') elem.title = elem.savedValues.title;	//restore title

    if (typeof elem.savedValues.classNames !== 'undefined')          // Restore classNames
        for (var i = 0; i < elem.savedValues.classNames.length; i++)
            elem.className = elem.className.replace(elem.savedValues.classNames[i][1], elem.savedValues.classNames[i][0]);

    //Remove prevent default event
    if (elem.removeEventListener)
        window.removeEventListener('click', elem.savedValues.event_preventDefault, true);
    else
        document.detachEvent('onclick', elem.savedValues.event_preventDefault);	//IE<9

    //Remove the saved values as they are no longer needed
    if (typeof elem.savedValues.colour !== 'undefined') delete elem.savedValues.colour;
    if (typeof elem.savedValues.title !== 'undefined') delete elem.savedValues.title;
    if (typeof elem.savedValues.classNames !== 'undefined') delete elem.savedValues.classNames;
    delete elem.savedValues.event_preventDefault;

    var keyCount = 0;
    for (var key in elem.savedValues) keyCount++;
    if (keyCount === 0)
        elem.savedValues = undefined;
}

/**
 * Get a random alphanumeric string (lowercase and numbers).
 *
 * @param {int} len - Length of the string to return
 */
Utils.prototype.randomStr = function (len) {
    return Math.random().toString(36).substr(2, len);
}

/**
 * Get a random string of specific length with chars from a alphabet.
 * @param {int} len - Length of the random string to return
 * @param {string} alphabet(optional) - The alphabet that the returned string char's will be comprised of. If not defined then a-zA-Z0-9 will be used.
 */
Utils.prototype.randomAlphaStr = function (len, alphabet) {
    var out = '';
    if (typeof alphabet === 'undefined')
        alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < len; i++)
        out += alphabet.charAt(Math.floor((Math.random() * alphabet.length)));
    return out;
}

/**
 * Schedule an event to occur onTransitionEnd for an element
 * @param {DOMelement} elem - the element that is to have the event scheduled against
 * @param {function} func - the function that is to be called on transitionEnd
 */
Utils.prototype.onTransistionEnd = function (elem, func) {
    var t, transition;
    var el = document.createElement('fakeelement');
    var transitions = {
        'transition': 'transitionend',
        'WebkitTransition': 'webkitTransitionEnd',
        'OTransition': 'oTransitionEnd',			//Not needed (recent versions of opera support unprefixed version)
        'MSTransition': 'msTransitionEnd',			//Not needed (MS10 supports unprefixed version)
        'MozTransition': 'transitionend'			//Not needed (FF supports camel case version)
    };

    for (var t in transitions) {
        if (el.style[t] !== undefined) {
            transition = transitions[t];
            break;
        }
    }

    //console.log(transition);

    if (typeof transition === 'undefined')
        func();
    else
        elem.addEventListener(transition, func, false);
}

//Will show a tr (height go from zero to init)
Utils.prototype.showTr = function (elem) {
    //TODO
    elem.style.display = '';
}

//Will hide a tr (height go to zero), if del=true then delete the elem from the dom once animation is complete
Utils.prototype.hideTr = function (elem, del) {
    del = typeof del === 'undefined' ? false : del;     //default del to false
    //TODO
    elem.style.display = 'none';

    if (del)
        elem.parentNode.removeChild(elem);
}

//Grows an element from height=0 to height=init
Utils.prototype.growIn = function (elem) {
    //TODO
    //needs to check that isn't already growin in
    elem.style.display = '';
}

//Shrink an element from height=init to height=0, if del=true then delete the elem from the dom once animation is complete
Utils.prototype.shrinkOut = function (elem, del) {
    del = typeof del === 'undefined' ? false : del;     //default del to false
    //TODO
    //needs to check that isn't already shrunkout
    elem.style.display = 'none';
    if (del)
        elem.parentNode.removeChild(elem);
}

//Check if a email is valid. return true if it is and false otherwise
Utils.prototype.validEmail = function (email) {
    if (email.indexOf("@") === -1)
        return false;
    else if (email.length < 3)
        return false;
    else if (email.indexOf(';') !== -1)
        return false;
    else
        return true;
}

/*
 * Smooth scroll to an element, or a position on the screen.
 * DEPENDENCIES: access to this.getLTpos(), and this.bezier
 * @param settings {
 * 	elem: document.getElementById('myid'),			//Element to scroll to
 * 	x: 100,				//x posistion to scroll to (shouldn't be set with elem)
 * 	y: 100,				//y posistion to scroll to (shouldn't be set with elem)
 * 	xOffset: 10,		// Lower values are closer to left side of screen
 * 	yOffset: 10,		// Lower values are closer to top of screen
 * 	xCenter:false,		//If set to true then the posistion we scroll to should be in the center of the screen
 * 	yCenter:true,		//If set to true then the posistion we scroll to should be in the center of the screen
 *  xIgnore:true,		//If true: wont scroll in the x direction (should be false if yIgnore=true)
 *  yIgnore:true,		//If true: wont scroll in the y direction (should be false if xIgnore=true)
 * 	scrollTime:1000,			//Animation time
 *  onFinish:function() {}      //Callback to run when scroll is finished
 * }
 *
 */
Utils.prototype.smoothScroll = function (settings) {

    var fps = 40;	//Frames per second

    //If settings.scrollTime is defined then we use that time to scroll otherwise we default to 1000
    var scrollTime = 3000;	//Time that we should spend scrolling
    if (typeof settings.scrollTime !== 'undefined')
        scrollTime = settings.scrollTime;

    //Get the location we are scrolling from
    var xScrollFrom = self.pageXOffset ? self.pageXOffset : document.documentElement.scrollLeft;
    var yScrollFrom = self.pageYOffset ? self.pageYOffset : document.documentElement.scrollTop;

    //Set the default values to scroll to
    var xScrollTo = xScrollFrom;
    var yScrollTo = yScrollFrom;

    //Get the size of the viewport
    var viewPortWidth = document.documentElement.clientWidth;
    var viewPortHeight = document.documentElement.clientHeight;

    //If settings.elem is defined then get its location and set it as the scrollto position
    if (typeof settings.elem !== 'undefined') {
        var scrollToElem = this.getLTPos(settings.elem);

        //Set the scrollto as this element
        xScrollTo = scrollToElem.x;
        yScrollTo = scrollToElem.y;

        //If xCenter is set to true then we want to center this element in the x coords of the window
        if (settings.xCenter === true) {
            if (settings.elem.offsetWidth < viewPortWidth) {
                xScrollTo -= (viewPortWidth * 0.5) - (settings.elem.offsetWidth * 0.5);
            }
        }
        //If yCenter is set to true then we want to center this element in the y coords of the window
        if (settings.yCenter === true) {
            if (settings.elem.offsetHeight < viewPortHeight) {
                yScrollTo -= (viewPortHeight * 0.5) - (settings.elem.offsetHeight * 0.5);
            }
        }

    }

    //If settings.x is defined then we will be scrolling to this x-position (so set it as the xScrollTo)
    if (typeof settings.x !== 'undefined') {
        xScrollTo = settings.x;
        if (settings.xCenter === true) {
            xScrollTo -= viewPortWidth * 0.5;
        }
    }

    //If settings.y is defined then we will be scrolling to this y-position (so set it as the yScrollTo)
    if (typeof settings.y !== 'undefined') {
        yScrollTo = settings.y;
        if (settings.yCenter === true) {
            yScrollTo -= viewPortHeight * 0.5;
        }
    }

    //If settings.xOffset is defined then we need to add it to the xScrollTo
    if (typeof settings.xOffset !== 'undefined')
        xScrollTo += settings.xOffset;

    //If settings.yOffset is defined then we need to add it to the yScrollTo
    if (typeof settings.yOffset !== 'undefined')
        yScrollTo += settings.yOffset;

    //if settings.xIgnore = true then xScrollTo = xScrollFrom;
    if (settings.xIgnore === true)
        xScrollTo = xScrollFrom;

    //if settings.yIgnore = true then yScrollTo = yScrollFrom;
    if (settings.yIgnore === true)
        yScrollTo = yScrollFrom;

    //This is the holder for the current time of the animation (0.0->1.0)
    var curTime = 0;

    var bezier = this.bezier;    //So we can access this.bezier in window.setInterval()

    //Set the function to be called every frame (1000/fps)
    var scrollEvent = window.setInterval(function () {

        curTime += 1000 / fps;	//Increment the current time

        //Work out the new value of window.scrollTo (both x and y).
        //Values will be inbetween 0.0-1.0, therefore they need to be multiplied by the difference of scrollTo and scrollFrom then that result added to scrollFrom.
        var bezierNext = bezier(curTime / scrollTime, {x: 0.0, y: 0.0}, {x: 0.8, y: 0.8}, {x: 1.0, y: 1.0}, {
            x: 1.0,
            y: 1.0
        });
        //var bezierNext = bezier(curTime/scrollTime, {x:0.0, y:0.0}, {x:1.5, y:0.0}, {x:1.5, y:1.5}, {x:1.0, y:1.0});
        //console.log('yScrollFrom:'+yScrollFrom, ' yScrollTo:'+yScrollTo, ' bezierNext.y:'+bezierNext.y);
        window.scrollTo(xScrollFrom + (xScrollTo - xScrollFrom) * bezierNext.x, yScrollFrom + (yScrollTo - yScrollFrom) * bezierNext.y);

        if (curTime >= scrollTime) {
            //Animation has ended (clean up)
            window.clearInterval(scrollEvent);
            window.scrollTo(xScrollTo, yScrollTo);
            if (typeof settings.onFinish !== 'undefined') settings.onFinish();
        }
    }, 1000 / fps);
}

/**
 * Bezier interpolation
 * @params t {Double} - time (between 0-1)
 * @params p0 {x,y} - start position (for ease sake keep at {x:0,y:0})
 * @params p1 {x,y} - control point 1 e.g. {x:0.23, y:0.63}
 * @params p2 {x,y} - control point 2 e.g. {x:0.38, y:1}
 * @params p3 {x,y} - end position (for ease sake keep at {x:1,y:1})
 * @returns {x,y} - a point on the bezier curve between p0 and p3 based on the 2 control points and time
 */
Utils.prototype.bezier = function (t, p0, p1, p2, p3) {
    var x = Math.pow((1 - t), 3) * p0.x + 3 * Math.pow((1 - t), 2) * t * p1.x + 3 * (1 - t) * Math.pow(t, 2) * p2.x + Math.pow(t, 3) * p3.x;
    var y = Math.pow((1 - t), 3) * p0.y + 3 * Math.pow((1 - t), 2) * t * p1.y + 3 * (1 - t) * Math.pow(t, 2) * p2.y + Math.pow(t, 3) * p3.y;
    return {x: x, y: y};
}

/**
 * Animate single property for a single element
 * Animations are self inhibiting (you cant have 2 animations on the same property on the same element)
 * @param elem {DOMElement} - The element to animate e.g. document.getElementById('myid')
 * @param from {numeric} - The initial value of the animation (this should be the current value for a smooth animation) e.g. 0
 * @param to {numeric} - The final value of the animation (value will persist after animation) e.g. 1500
 * @param time {numeric} - How long the animation should take (ms) e.g. 2500
 * @param property {string} - The property to animate e.g. 'width'
 * @param units {string}{optional}{default:'px'} - e.g. 'px'/'em'/''
 * @param onComplete {function}{optional} - e.g. callback that will be run once the animation finishes
 **/
Utils.prototype.animate = function (elem, from, to, time, property, units, onComplete) {
    if (typeof units === 'undefined') units = 'px';	// Default units to 'px'

    var FPS = 30;
    var animationFrequency = 1000 / FPS;

    /** START setup the animation clock **/
    // Check for the existance of jsAnimation global variable
    if (typeof jsAnimation === 'undefined') {
        var clockTick = function () {
            for (var i = jsAnimation.elems.length - 1; i >= 0; i--) {		// For each element in the global variable jsAnimation
                var element = jsAnimation.elems[i];
                if (element.jsAnimations)
                    for (var animName in element.jsAnimations)		// For each animation on this element
                        element.jsAnimations[animName]();	//Tick
                else
                    jsAnimation.elems.splice(i, 1);	//Remove this element from jsAnimation.elems as there are no animations left to run
            }

            // If there are no more elements that require animation, clean up
            if (jsAnimation.elems.length === 0) {
                window.clearInterval(jsAnimation.clock);
                delete jsAnimation.clock;
                delete jsAnimation.elems;
                delete jsAnimation;	// Remove the global var (this should be 'delete window.jsAnimation' but IE cant understand that)
            }

        };
        jsAnimation = {};	//Create global variable
        jsAnimation.elems = Array();
        jsAnimation.clock = window.setInterval(clockTick, animationFrequency);
    }
    /** END setup the animation clock **/

    /** START setup a new animation **/
    var totalNumIterations = parseInt(time / animationFrequency);
    var changePerIteration = (to - from) / totalNumIterations;
    var curIteration = 0;
    var curValue = from;
    var animationName = property + 'Anim';

    // setup elem.jsAnimations (this will hold all the tick events for this element)
    if (typeof elem.jsAnimations === 'undefined')
        elem.jsAnimations = {};

    //Add elem to jsAnimation.elems if it doesn't already exist
    var elemSetup = false;
    if (!jsAnimation.elems)
        jsAnimation.elems = Array();
    for (var i = 0; i < jsAnimation.elems.length; i++)
        if (jsAnimation.elems[i] === elem)
            elemSetup = true;
    if (!elemSetup)
        jsAnimation.elems.push(elem)

    // Create the tick event for this animation
    elem.jsAnimations[animationName] = function () {
        curValue += changePerIteration;
        elem.style[property] = curValue + units;

        curIteration++;
        if (curIteration >= totalNumIterations) {
            delete elem.jsAnimations[animationName];
            if (Object.keys(elem.jsAnimations).length === 0) delete elem.jsAnimations;
            elem.style[property] = to + units;
            if (typeof onComplete !== 'undefined') onComplete();	// Run the on complete if one is defined
        }
    };
    /** END setup a new animation **/
}

/**
 * PHP's htmlspecialchars for javascript
 * @params text {string} - String to be escaped
 * @returns {string} - escaped string
 */
Utils.prototype.htmlspecialchars = function (text) {
    return text
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

/**
 * Pad a number with 0's
 * Notes:
 *  - negative numbers wont work
 *  - If 'num' is longer than 'size' then 'num' is returned unchanged (will be longer than 'size')
 * @params num {int/string} - number to pad e.g. 1
 * @params size {int} - the resulting size of the number after padding e.g. 9 will give 000000001
 * @returns {string} - the number padding to 'size' length e.g. pad(2, 5) returns 00002
 */
Utils.prototype.pad = function (num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

/**
 * Get the size of the viewport
 * @returns {Object} - returns a object like {width:int, height:int} e.g. {1920, 496}
 */
Utils.prototype.getViewportSize = function () {
    var e = window, a = 'inner';
    if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
    }
    return {width: e[a + 'Width'], height: e[a + 'Height']}
}

/**
 * Replace a DOMNode with some HTML.
 * NOTE:    - The html may contain multiple elements within it e.g. "<div id='1'>hi1<div> <div id='2'>hi2<div>"
 *        - This probably doesn't work very well with elements like <tr>
 * @param elem {DOMNode} - node from e.g. document.getElementById('x')
 * @param html {string} - e.g. "<div style='background:yellow'>hi<div>"
 * @param tr {bool} - True: if the element is a <tr>
 * @return {array<DOMNode>} - an array of DOMNodes that were added
 */
Utils.prototype.replaceElemWithHTML = function (elem, html, tr) {
    if (typeof tr === 'undefined')
        tr = html.trim().indexOf('<tr') === 0 ? true : false;	// If tr is not specified then try to auto detect

    var newElem = document.createElement(tr ? 'tbody' : 'div');
    newElem.innerHTML = html.trim();

    var children = newElem.childNodes;
    var out = [];
    for (var i = 0; i < children.length; i++)
        out.push(elem.parentNode.insertBefore(children[i], elem));

    elem.parentNode.removeChild(elem);

    return out;
}

/**
 * Get a style for an element e.g. getElemStyle(myElement, 'border-top-width')
 * NOTE: be careful with IE8 e.g. 'border-top-width' may return in 'em' rather than 'px'
 * @param element {DOMElement}
 * @param style {string} e.g. 'border-left-width'
 * @returns {string} e.g. '34px'
 */
Utils.prototype.getElemStyle = function (element, style) {
    // The DOM Level 2 CSS way
    if ('getComputedStyle' in window) {
        var cs = getComputedStyle(element, '');
        if (cs.length !== 0)
            return cs.getPropertyValue(style);

        // Opera workaround. Opera doesn't support `item`/`length`
        // on CSSStyleDeclaration.
        else
            return cs[style];

        // The IE way
    } else {//if ('currentStyle' in element) {
        var cs = element.currentStyle;
        return cs[style];
    }
};

/**
 * Creates a <style> element with @newStyle as its content
 * @param newStyle {string} - The content of the <style> elem
 * @returns {string} - the id of the new <style> added
 */
Utils.prototype.addStyle = function (newStyle, styleId) {
    if (!(this instanceof Utils)) {
        console.error("'this' is bound incorrectly");
    }
    var style;
    if (styleId) {
        style = document.getElementById(styleId);
        if (style.styleSheet && !style.sheet) {
            style.styleSheet.cssText += newStyle;
        } else {
            style.innerHTML += newStyle;
        }
    } else {
        styleId = "style_" + (new Date().getTime());
        style = document.createElement('style');
        style.id = styleId;
        style.rel = "stylesheet";
        //style.type = "text/css";
        style.setAttribute('type', 'text/css');
        document.getElementsByTagName('head')[0].appendChild(style);
        if (style.styleSheet && !style.sheet) {
            style.styleSheet.cssText = newStyle;
        } else {
            style.innerHTML = newStyle;
        }

        if (!(this.styles instanceof Array))
            this.styles = [];
        this.styles.push(style);
    }
    return styleId;
};

/**
 * Remove all/one styles previously added by this.addStyle()
 * @returns {Array} of styles that were removed.
 */
Utils.prototype.removeStyles = function (styleId) {
    if (!(this instanceof Utils)) {
        console.error("'this' is bound incorrectly");
    }
    var ret = [], i;
    if (styleId) {
        if (this.styles) {
            for (i = this.styles.length - 1; i >= 0; i--) {
                if (this.styles[i].id === styleId) {
                    this.styles[i].parentNode.removeChild(this.styles[i]);
                    ret.push(this.styles.splice(i, 1));
                }
            }
        }
    } else {
        if (this.styles) {
            for (i = this.styles.length - 1; i >= 0; i--) {
                this.styles[i].parentNode.removeChild(this.styles[i]);
                ret.push(this.styles.splice(i, 1));
            }
        }
    }
    return ret;
};

/**
 * Will auto resize an iframe to fit its contents. If addInterval=true then an interval is set to resize the iframe every one second.
 * @param elem {DOMElement} <iframe>
 * @param addInterval {bool}{optional}{default:false} true: schedule this method every one second
 * @param resizeWidth {bool}{optional}{default:false} true: width will be resized as well
 */
Utils.prototype.autoSizeFrame = function (elem, addInterval, resizeWidth) {
    if (typeof addInterval === 'undefined') addInterval = false;		// Default addInterval to false
    if (typeof resizeWidth === 'undefined') resizeWidth = false;		// Default resizeWidth to false

    var resizeEvent = function (elem) {

        elem.style.height = 0;
        if (window.getComputedStyle(elem))
            window.getComputedStyle(elem).getPropertyValue("height");
        var newheight = elem.contentWindow.document.body.scrollHeight;
        elem.style.height = newheight + 'px';

        if (resizeWidth) {
            elem.style.height = 0;
            if (window.getComputedStyle(elem))
                window.getComputedStyle(elem).getPropertyValue("height");
            var newheight = elem.contentWindow.document.body.scrollHeight;
            elem.style.height = newheight + 'px';
        }
    }

    if (addInterval)
        window.setInterval(resizeEvent.bind(this, elem), 1000);	// Call resize event every one second

    resizeEvent(elem);
};

/**
 * Will convert a number to string e.g.
 *   number_format(27753.98746, 2, '.', ',') => '27,753.99'
 *   number_format(27753.98746, 3, '#', '&') => '27&753#987'
 *   number_format(27753.98746) => '27,753.99'
 * @param number {various} the number we want to format (must be valid when parsed by parseFloat())
 * @param decimals {int}{optional}{default:2} number of decimal places to keep
 * @param decimal_sep {string}{optional}{default:'.'} character used as decimal separator
 * @param thousands_sep {string}{optional}{default:','} char used as thousands separator
 * @return {string} - the formatted number e.g. '27,753.99'
 */
Utils.prototype.number_format = function (number, decimals, decimal_sep, thousands_sep) {
    var n = parseFloat(number),
        c = isNaN(decimals) ? 2 : Math.abs(decimals), //if decimal is zero we must take it, it means user does not want to show any decimal
        d = decimal_sep || '.', //if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)

        t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep, //if you don't want to use a thousands separator you can pass empty string as thousands_sep value

        sign = (n < 0) ? '-' : '',

        //extracting the absolute value of the integer part of the number and converting to string
        i = parseInt(n = Math.abs(n).toFixed(c)) + '',

        j = ((j = i.length) > 3) ? j % 3 : 0;
    if (isNaN(n)) return "NaN";
    return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
};

/**
 * Round number to 'precision' decimal places e.g.
 *   utils.round(67.5548, 2);    => 67.55
 *   utils.round(67.5558, 2);    => 67.56
 *   utils.round(67.4);          => 67
 *   utils.round(67.5);          => 68
 *   utils.round(-67.4);         => -67
 *   utils.round(-67.5);         => -68
 *   utils.round(5455.5568, -3); => ERROR
 * @param value {float} the number we want to round
 * @param precision {int}{optional}{default:0} number of decimal places to round to (between 0-20)(cannot be negative)
 * @return {float} - The rounded value
 */
Utils.prototype.round = function (value, precision) {
    if (typeof precision === 'undefined') precision = 0;		// default to 0
    return parseFloat(value.toFixed(precision));
};

/**
 * Round number to 'precision' decimal places and round half down e.g.
 *   utils.roundHalfDown(67.5558, 2);    => 67.55
 *   utils.roundHalfDown(67.5568, 2);    => 67.56
 *   utils.roundHalfDown(67.5);          => 67
 *   utils.roundHalfDown(67.6);          => 68
 *   utils.roundHalfDown(-67.5);         => -67
 *   utils.roundHalfDown(-67.6);         => -68
 *   utils.roundHalfDown(5555.5568, -3); => 5000
 *   utils.roundHalfDown(5655.5568, -3); => 6000
 * @param value {float} the number we want to round
 * @param precision {int}{optional}{default:0} number of decimal places to round to
 * @return {float} - The rounded value
 */
Utils.prototype.roundHalfDown = function (value, precision) {
    var sgn, p, v, v2;
    precision |= 0; // making sure precision is integer
    sgn = value < 0 ? -1 : +1; // sign of the number
    value = Math.abs(value);

    p = Math.pow(10, precision);
    v = value * p;
    v2 = v * 10;
    v2 = Math.floor(v2);
    v2 = v2 % 10;
    i = v2 <= 5 ? 0.0 : +1.0;

    v = Math.floor(v);
    v += i;
    v = v / p;

    return v * sgn;
};

/**
 * Float arithmetic in Javascript can give seemingly incorrect results e.g.
 *        0.1 + 0.3 = 0.30000000000000004
 *        1 + 7.13 = 8.129999999999999
 * This function attempts to solve this issue e.g.
 *        utils.floatFix(0.1 + 0.2) = 0.3
 *        utils.floatFix(1 + 7.13) = 8.13
 * NOTES (on Javascript):
 *        - Integers are considered accurate up to 15 digits
 *        - The maximum number of decimals is 17 (the higher the value (>= 1) the less DP there are)
 *        - JavaScript Numbers are Always 64-bit Floating Point
 * @param value {float} the number we want to 'fix'
 * @param precision {int}{optional}{default:12} how precise to keep the number (recommend not going higher than 12)
 * @return {float} - The 'fixed' number
 */
Utils.prototype.floatFix = function (value, precision) {
    if (typeof precision === 'undefined') precision = 12;		// default to 12
    return parseFloat(value.toPrecision(precision));
};

/**
 * This will add months to a date, and if this date ends up as e.g. 2014-02-30 then it will returned as 2014-02-28
 * @param date {Date} the date that we want to add months to
 * @param months {int} number of months to add onto date
 * @return {Date} - date + months
 */
Utils.prototype.dateAddMonth = function (date, months) {
    var day = date.getDate();
    var d2 = new Date(date.getFullYear(), date.getMonth(), 1);
    d2.setMonth(d2.getMonth() + months);

    var month = d2.getMonth();
    var year = d2.getFullYear();

    d2.setDate(day);
    if (month !== d2.getMonth()) {
        d2.setFullYear(year);
        d2.setMonth(month);
        var lastdayofmonth = new Date(year, month + 1, 0).getDate();		// Month is 1 based
        d2.setDate(lastdayofmonth);
    }
    return d2;
};

/**
 * JQuery form loading.
 * Disable all input elems and show button throbber.
 * @param $form {string/jQueryNode} - STRING: Selector for form (using a unique selector is recommended e.g. "#myformid") - jQueryNode: e.g. $("#myformid")
 * @param set {bool} TRUE: Will set loading status. | FALSE: Will remove loading status.
 * @return
 */
Utils.prototype.jFormLoading = function ($form, set) {
    if (typeof $form === 'string')
        $form = $($form);
    if (set) {
        // Disabled all :input's ( if an input is already disabled save this info in .data() )
        $form
            .find(":input")
            .each(function () {
                if ($(this).is(":disabled"))
                    $(this).data('disabled', true);
                $(this).prop("disabled", true);
            });
        $form.addClass("clsloading");
    } else {
        // Enable all :input's ( if an input was previously disabled (info in data()) then don't enable it )
        $form
            .find(":input")
            .each(function () {
                if ($(this).data('disabled')) {
                    $(this).removeData('disabled');
                } else {
                    $(this).prop("disabled", false);
                }
            });
        $form.removeClass("clsloading");
    }
};

/**
 * Retrieve variables out of the QueryString
 * @param key {string} param name
 * @return {string/null} param value NOTE: null returned if value = "" e.g. f.e?var=
 */
Utils.prototype.queryString = function (key) {
    key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx control chars
    var match = location.search.match(new RegExp("[?&]" + key + "=([^&]+)(&|$)"));
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};

/**
 * Is element visible in viewport
 * @param entirely {bool}{optional}{default:true}
 * @requires jQuery
 * @return {bool}
 */
Utils.prototype.isScrolledIntoView = function (elem, entirely) {
    entirely = typeof entirely === 'undefined' ? true : entirely;

    var $elem = $(elem);
    var $window = $(window);

    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();

    var elemTop = $elem.offset().top;
    var elemBottom = elemTop + $elem.height();

    if (entirely)
        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    else
        return ((elemTop <= docViewBottom) && (elemBottom >= docViewTop));
};

/**
 * Scroll to element if not showing (only look at Y axis)
 * @param elem {DOMElement}{optional}{default:true}
 * @param force {bool}{optional}{default:false} - True: Will scroll to element even if it is showing
 * @param entirely {bool}{optional}{default:false} - True: Will only scroll to element if it is entirely hidden. False: Will scroll to element if it is partially hidden.
 * @param offset {double}{optional}{default:-70} - (px) Scroll offset
 * @requires jQuery
 * @return {bool}
 */
Utils.prototype.scrollToElem = function (elem, force, entirely, offset) {
    force = typeof force === 'undefined' ? false : force;
    entirely = typeof entirely === 'undefined' ? false : entirely;
    offset = typeof offset === 'undefined' ? -70 : offset;

    // Do we want to scroll to element?
    var scroll = false;
    if (!force) {
        scroll = true;
    } else {
        if (this.isScrolledIntoView(elem, !entirely)) {
            scroll = true;
        }
    }

    if (scroll) {
        // get element top
        var top = this.getTopPos();

        this.smoothScroll({
            'elem': elem,
            'yOffset': offset,
            'scrollTime': 300,
        });
    }
};

Utils.prototype.ucfirst = function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

Utils.prototype.createCookie = function (name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
};

Utils.prototype.readCookie = function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

Utils.prototype.eraseCookie = function (name) {
    createCookie(name, "", -1);
};

Utils.prototype.addEventListener = function(elem, type, func) {
	if(elem.addEventListener) {
		elem.addEventListener(type, func, false);
	} else {
		elem.attachEvent("on" + type, func);	//IE pre V9
	}
};

//Give function(){}.bind(this) to IE<=8
if (!Function.prototype.bind) {
    Function.prototype.bind = function (oThis) {
        if (typeof this !== "function") {
            // closest thing possible to the ECMAScript 5 internal IsCallable function
            throw new TypeError("Function.prototype.bind - what is trying to be bound is not callable");
        }

        var aArgs = Array.prototype.slice.call(arguments, 1),
            fToBind = this,
            fNOP = function () {
            },
            fBound = function () {
                return fToBind.apply(this instanceof fNOP && oThis
                        ? this
                        : oThis,
                    aArgs.concat(Array.prototype.slice.call(arguments)));
            };

        fNOP.prototype = this.prototype;
        fBound.prototype = new fNOP();

        return fBound;
    };
}

//Add RegExp.escape()
if (typeof RegExp.escape !== 'function') {
    RegExp.escape = function (s) {
        return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    };
}

//Give string.trim() to IE<=8
if (typeof String.prototype.trim !== 'function') {
    String.prototype.trim = function () {
        return this.replace(/^\s+|\s+$/g, '');
    }
}

//Give Date.toISOString() to IE<=8
if (!Date.prototype.toISOString) {
    ( function () {

        function pad(number) {
            if (number < 10) {
                return '0' + number;
            }
            return number;
        }

        Date.prototype.toISOString = function () {
            return this.getUTCFullYear() +
                '-' + pad(this.getUTCMonth() + 1) +
                '-' + pad(this.getUTCDate()) +
                'T' + pad(this.getUTCHours()) +
                ':' + pad(this.getUTCMinutes()) +
                ':' + pad(this.getUTCSeconds()) +
                '.' + (this.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
                'Z';
        };

    }() );
}

//Give array.indexOf() to IE<=8
if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (elt /*, from*/) {
        var len = this.length >>> 0;

        var from = Number(arguments[1]) || 0;
        from = (from < 0)
            ? Math.ceil(from)
            : Math.floor(from);
        if (from < 0)
            from += len;

        for (; from < len; from++) {
            if (from in this &&
                this[from] === elt)
                return from;
        }
        return -1;
    };
}