$(function () { $('[data-toggle="tooltip"]').tooltip(); });
$(function () { $('[data-toggle="popover"]').popover(); });

// Clicking these sorts of links shouldn't scroll to the top of the page
$( '#primary-menu').find('ul li a[href="#"]' ).click( function(){
    return false;
});

// Disable inputs on page unload (this should prevent double clicking form submit button errors)
window.onbeforeunload = function () {
    var inputs = document.getElementsByTagName("INPUT");
    for( var i=0; i<inputs.length; i++ ) {
        if (inputs[i].type == "button" || inputs[i].type == "submit") {
            inputs[i].disabled = true;
        }
    }
};