/***

 IMPORTANT: jQuery must be included before this file.

 This file is ONLY interested in <input type='date'> elements.
 For each <input> convert 'value' to appropriate format ('yyy-mm-dd' or 'dd-M-yyyy'). If type='date' is not supported jQuery's datepicker will be amended to this <input>.

 Use:
 - Call "jDatePicker.initialise();" as much as you want (whenever a new input is added) as it is idempotent.
 - Methods are added to work in a 'static' way so there is no need to instantiate the jDatePicker Object
 - <input data-jdatepicker-nullToToday="true/false"/> to override nullToToday for a specific element

 ***/

/** OnDomReady call jDatePicker.initialise() **/
jQuery(function () {
	jDatePicker.initialise(true);
});

/** jDatePicker Object **/
function jDatePicker() {}

/**
 * For each <input type='date'>
 * 		if type='date' is supported
 * 			convert the value to yyyy-mm-dd format.
 *		else
 * 			convert the value to dd-M-yyyy format.
 * 			add jQuery's datepicker to this <input>
 *
 * jQuery's datepicker is idempotent and therefore so is this method.
 * @param [nullToToday=false] {boolean} - Whether or not to default null values to today's date
 * @param [debug=false] {boolean} - Whether or not to use debug mode.
 * 		(<input>'s will be highlighted in red if its contents are in an unrecognised format, also any <input> that has type!='date' will be highlighted in orange)
 **/
jDatePicker.initialise = function(nullToToday, debug) {
	if(typeof nullToToday === 'undefined') nullToToday = false;		//default nullToToday to false
	if(typeof debug === 'undefined') debug = false;		//default debug mode to false

	//check if <input type='date'> is supported
	if (this.isDateInputSupported()) {
		jQuery('input[type="date"]').each(function() {

			// elems <input data-jdatepicker-nullToToday="true/false"/> over rides nullToToday
			var thisNullToToday = this.getAttribute('data-jdatepicker-nullToToday');
			thisNullToToday = thisNullToToday ? (thisNullToToday === "true") : nullToToday;

			//try to convert the current value to Date
			var date = jDatePicker.parseDate(this.getAttribute('value'), thisNullToToday);

			//if date is valid set it to this.value
			if(date !== false && !isNaN(date.getTime()))
				this.value = jQuery.datepicker.formatDate('yy-mm-dd', date);
			else if(debug)
				this.style.background = 'red';
		});
	} else {
		jQuery('input[type="date"]').datepicker({ beforeShow:jDatePicker.onBeforeShow, dateFormat: 'dd-M-yy', changeMonth: true, changeYear: true }).each(function () {

			// elems <input data-jdatepicker-nullToToday="true/false"/> over rides nullToToday
			var thisNullToToday = this.getAttribute('data-jdatepicker-nullToToday');
			thisNullToToday = thisNullToToday ? (thisNullToToday === "true") : nullToToday;

			//try to convert the current value to Date
			var date = jDatePicker.parseDate(this.value, thisNullToToday);

			//if date is valid set it to this.value
			if(date !== false && !isNaN(date.getTime()))
				this.value = jQuery.datepicker.formatDate('dd-M-yy', date);
			else if(debug)
				this.style.background = 'red';
		});
	}

	// If we are in debug mode highlight any <input>'s that have type!='date' in orange
	if(debug)
		jQuery('input:not([type="date"])').css('background-color', 'orange');
};

/**
 * datepicker.beforeShow event. This will convert <input>.value to 'dd-M-yy' format so that the datepicker can understand it.
 * @param input {HTMLElement} - input field
 * @param inst {Object} - current datepicker instance
 */
jDatePicker.onBeforeShow = function(input, inst) {
	//try to convert the current value to Date
	var date = jDatePicker.parseDate(input.value, false);

	//if date is valid set it to this.value
	if(date !== false && !isNaN(date.getTime()))
		input.value = jQuery.datepicker.formatDate('dd-M-yy', date);
};

/**
 * Convert the format of a single <input>'s 'value' to a given format.
 * @param input {HTMLElement} - input element
 * @param [format='dd-M-yy'] {string} - string representation of the output format.
 * @param [redErr=false] {boolean} - Whether or not, if unable to recongnise the format to set the background of the element to red.
 * @return {boolean} - True on success. False: on failure (unrecognised formatted date in the input)
 */
jDatePicker.convertFormat = function(input, format, redErr) {
	//if(typeof format === 'undefined') format = 'dd-M-yy';		//default format mode to 'dd-M-yy' e.g. '01-Mar-2012'
	if(typeof redErr === 'undefined') redErr = false;		// default redErr to false;

	var str = jDatePicker.dateToString(input.value, format);

	//if date is valid set this.value to this date formatted according to 'format'
	if( str === false ) {
		if( redErr === true ) input.style.backgroundColor = 'red';
		return false;
	} else {
		input.value = str;
		return true;
	}
};

/**
 * Convert the format of a date to a given format.
 * @param date {*} - date that is valid for jDatePicker.parseDate() to handle
 * @param [format='dd-M-yy'] {string} - string representation of the output format.
 * @return {*} - {Date} on success. False: on failure (unrecognised formatted date)
 */
jDatePicker.dateToString = function(date, format) {
	if(typeof format === 'undefined') format = 'dd-M-yy';		//default format mode to 'dd-M-yy' e.g. '01-Mar-2012'

	//try to convert the current value to Date
	date = jDatePicker.parseDate(date, false);

	//if date is valid return date formatted according to 'format'
	if(date !== false && !isNaN(date.getTime()))
		return jQuery.datepicker.formatDate(format, date);

	return false;
};

/**
 * Parse in a string representation of a date and this will parse back a Date object with this value. Or false on unrecognised date format.
 * This method is required because 'new Date()' is fairly limited and inconsistent across browsers.
 * Specific NOTE: US date will be recognised as a UK date. I.e. '13/04/2012' is d/m/yy format
 * @param val {string} - string representation of a date e.g. '13-04-2012'
 * @param [nullToToday=false] {boolean} - Whether or not to default null values to today's date
 * @return {Date|false} - same as 'new Date(val)' with a few additional formats accepted. Or false on unrecognised date format.
 **/
jDatePicker.parseDate = function(val, nullToToday) {
	if(typeof nullToToday === 'undefined') nullToToday = false;		//default nullToToday to false

	if(typeof val === 'undefined' || val === null) {
		if(nullToToday === true)
			return new Date();
		else
			return false;
	}

	if(this.isDate(val))	//Check if value parsed in is already a date
		return val;

	var dateFormat = '';
	var tVal = val.replace(/\//g, '-');		//create a test value that has all '/' replaced with '-'

	/** These next tests are before we test with "new Date(val)" to override its default implementation (e.g. US dates) **/
	// test for straight number	  e.g. '-43', '2', '1362344611753'
	if(/^-?\d+$/.test(val)) {
		return false;	// At the moment we don't want to handle this input (in the future we may want unixtimestamps to be handled)

		// test for d-m-yy	e.g. '2-4-2012', '02-04-2012'
	} else if(/^[0-9]{1,2}-[0-9]{1,2}-[0-9]{4}$/.test(tVal)) {
		dateFormat = "d-m-yy";

//	// test for d-mm-yy	e.g. '2-04-2012'
//	} else if(/^[0-9]{1}-[0-9]{2}-[0-9]{4}$/.test(tVal)) {
//		dateFormat = "d-mm-yy";

//	// test for dd-m-yy	e.g. '02-4-2012'
//	} else if(/^[0-9]{2}-[0-9]{1}-[0-9]{4}$/.test(tVal)) {
//		dateFormat = "dd-m-yy";

//	// test for dd-mm-yy	e.g. '02-04-2012'
//	} else if(/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(tVal)) {
//		dateFormat = "dd-mm-yy";

		// test for d-m-y	e.g. '2-4-12', '02-04-12'
	} else if(/^[0-9]{1,2}-[0-9]{1,2}-[0-9]{2}$/.test(tVal)) {
		dateFormat = "d-m-y";

//	// test for d-mm-y	e.g. '2-04-12'
//	} else if(/^[0-9]{1}-[0-9]{2}-[0-9]{2}$/.test(tVal)) {
//		dateFormat = "d-mm-y";

//	// test for dd-m-y	e.g. '02-4-12'
//	} else if(/^[0-9]{2}-[0-9]{1}-[0-9]{2}$/.test(tVal)) {
//		dateFormat = "dd-m-y";

//	// test for dd-mm-y	e.g. '02-04-12'
//	} else if(/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/.test(tVal)) {
//		dateFormat = "dd-mm-y";

	}

	//Check if we have found a matching format
	if(dateFormat !== '') {
		try {
			return jQuery.datepicker.parseDate(dateFormat, tVal);
		} catch(e) {
			console.error(tVal + ' is not in the format "' + dateFormat + '". (' + val + ') ' + e);		// If we get here for a valid date a regex is probably wrong
			return false;
		}
	}

	/** We haven't found out what format the date is in yet so, try use JavaScript's "new Date(val)" to parse the date **/
	var date = new Date(val);
	if(this.isDate(date)) {	//Check if date is valid
		return date;
	}

	/** Date() couldn't work out the date format so see if we can **/

	// test for d-M-yy	e.g. '2-Sep-2012', '02-Sep-2012'
	if(/^[0-9]{1,2}-[A-Za-z]{3}-[0-9]{4}$/.test(tVal)) {
		dateFormat = "d-M-yy";

//	// test for dd-M-yy	e.g. '02-Sep-2012'
//	} else if(/^[0-9]{2}-[A-Za-z]{3}-[0-9]{4}$/.test(tVal)) {
//		dateFormat = "dd-M-yy";

		// test for d-MM-yy	e.g. '2-September-2012', '02-September-2012'
	} else if(/^[0-9]{1,2}-[A-Za-z]{3,9}-[0-9]{4}$/.test(tVal)) {
		dateFormat = "d-MM-yy";

//	// test for dd-MM-yy	e.g. '02-September-2012'
//	} else if(/^[0-9]{2}-[A-Za-z]{3,9}-[0-9]{4}$/.test(tVal)) {
//		dateFormat = "dd-MM-yy";

		// test for d-M-y	e.g. '2-Sep-12', '02-Sep-12'
	} else if(/^[0-9]{1,2}-[A-Za-z]{3}-[0-9]{2}$/.test(tVal)) {
		dateFormat = "d-M-yy";

//	// test for dd-M-y	e.g. '02-Sep-12'
//	} else if(/^[0-9]{2}-[A-Za-z]{3}-[0-9]{2}$/.test(tVal)) {
//		dateFormat = "dd-M-yy";

		// test for d-MM-y	e.g. '2-September-12', '02-September-12'
	} else if(/^[0-9]{1,2}-[A-Za-z]{3,9}-[0-9]{2}$/.test(tVal)) {
		dateFormat = "d-MM-yy";

//	// test for dd-MM-y	e.g. '02-September-12'
//	} else if(/^[0-9]{2}-[A-Za-z]{3,9}-[0-9]{2}$/.test(tVal)) {
//		dateFormat = "dd-MM-yy";

		// test for yy-M-d	e.g. '2012-Sep-3', '2012-Sep-03'
	} else if(/^[0-9]{4}-[A-Za-z]{3}-[0-9]{1,2}$/.test(tVal)) {
		dateFormat = "yy-M-d";

//	// test for yy-M-dd	e.g. '2012-Sep-03'
//	} else if(/^[0-9]{4}-[A-Za-z]{3}-[0-9]{2}$/.test(tVal)) {
//		dateFormat = "yy-M-dd";

		// test for yy-m-d	e.g. '2012-2-3', '2012-02-03'		//IE8
	} else if(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/.test(tVal)) {
		dateFormat = "yy-m-d";

	}

	//Check if we have found a matching format
	if(dateFormat !== '') {
		try {
			return jQuery.datepicker.parseDate(dateFormat, tVal);
		} catch(e) {
			console.error(tVal + ' is not in the format "' + dateFormat + '". (' + val + ') ' + e);		// If we get here for a valid date a regex is probably wrong
		}
	}

	return false;
};

/**
 * Check if <input type="date"> is supported.
 * @return {boolean} - true if it is, false otherwise
 **/
jDatePicker.isDateInputSupported = function() {
	var elem = document.createElement('input');
	elem.setAttribute('type', 'date');
	elem.value = 'foo';
	return elem.type == 'date' && elem.value != 'foo';
};

/**
 * Checks if a given date is a valid Date() Object (not 'invalid date').
 * @param date {Date} - Date object to check. e.g. new Date()
 * @return {boolean} - true if it is, false otherwise
 */
jDatePicker.isDate = function(date) {
	return Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date.getTime());	//Check if date is valid
};