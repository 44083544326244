(function ( $ ) {

    /**
     * Get elements height (maybe hidden)
     * @param $element {jQuery}
     * @returns {number}
     */
    var elementInternalHeight = function($element) {
        var height = 0;
        $element.children().each(function () {
            height = height + $(this).outerHeight(false);
        });
        return height;
    };

    var getDistLeft = function($element) {
        return $element.height() + $element.offset().top - $(window).height() - $(window).scrollTop();
    };

    $.fn.nwInfiniteScroll = function( options ) {

        var $this = this;

        // Default options.
        var settings = $.extend({

            // These are the defaults.
            scrollOffset: 100,      // px
            prefetchPages: 1,       // Number of pages to prefetch
            addFirst: false

            /**
             * REQUIRED
             * Get/cache the next "pages" data
             * @param page {int} starting at 1 and incrementing by one each page
             * @param success {function} Call this once you are done and provide data e.g. success(data)
             * @return {mixed}
             *      NULL: no more pages exist (every page is loaded).
             *      Otherwise: the data to be passed to setPage()
             */
            // getNextPageData: function(page, success) {}

            /**
             * REQUIRED
             * Callback that will be passed the result from getNextPageData()
             * @param data {mixed} data returned from getNextPageData()
             */
            // addPage: function(data) {}

            /**
             * OPTIONAL
             * Callback to set loading status.
             * @param set {bool} TRUE: Set loading status FALSE: remove loading status
             */
            // setLoading: function(set) {}

            /**
             * OPTIONAL
             * Callback for when all pages are loaded (when getNextPageData() returns false)
             */
            // done: function() {}

        }, options );

        if( !settings.getNextPageData ) {
            console.error("nwInfiniteScroll failed because getNextPageData() was not specified.");
            return this;
        }

        if( !settings.done ) {
            console.error("nwInfiniteScroll failed because done() was not specified.");
            return this;
        }

        // Cache of preFetched pages
        var preFetched = [];

        // Is loading status set for the user?
        var isLoading = false;

        // Is a page fetch currently running?
        var isFetching = false;

        // The next page that is fetched should immediately have pageAdd() called on it
        var addNext = settings.addFirst;

        // Everything is done?
        var isDone = false;

        // This is the max page fetched
        var curPage = 0;

        // Now the functions

        /**
         * This will retrieve new data from settings.getNextPageData()
         * and either store it in preFetched OR call settings.addPage with it
         */
        var fetch = function() {

            var success = function(newData) {

                isFetching = false;

                if( isLoading ) {
                    isLoading = false;

                    if( settings.setLoading ) {
                        settings.setLoading(false);
                    }
                }

                if( newData !== null ) {

                    preFetched.push( newData );
                    if( addNext ) {
                        addNext   = false;
                        settings.addPage( preFetched.shift() );
                    }
                    tryFetch();

                } else {

                    if( settings.done ) {
                        settings.done();
                    }
                    isDone = true;

                }

            };


            if( addNext && !isLoading ) {
                isLoading = true;
                if( settings.setLoading ) {
                    settings.setLoading(true);
                }
            }

            if( !isFetching ) {
                isFetching = true;

                settings.getNextPageData(++curPage, success);
            }

        };

        /**
         * This will make sure that preFetched length is bigger than settings.prefetchPages
         * @param [force=false] {boolean}
         */
        var tryFetch = function(force) {
            if( force || preFetched.length < settings.prefetchPages ) {
                fetch();
            }
        };

        /**
         * Call settings.addPage either with data that was pre-fetched or fetch new data and call it
         */
        var tryAddPage = function() {
            if( preFetched.length > 0 ) {
                settings.addPage( preFetched.shift() );
                tryFetch();
            } else {
                addNext = true;
                tryFetch(true);
            }
        };

        $( window ).scroll(function() {
            if( !isDone || preFetched.length > 0 ) {
                //console.log(getDistLeft($this), settings.scrollOffset);
                if( getDistLeft($this) < settings.scrollOffset ) {
                    tryAddPage();
                }
            }
        });

        tryFetch();

        return this;

    };

}( jQuery ));